@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "SuisseIntl-Bold";
    src: local("SuisseIntl-Bold"), url(../font/SuisseIntl-Bold.ttf) format("opentype");
}
@font-face {
    font-family: "SuisseIntl-Regular";
    src: local("SuisseIntl-Regular"), url(../font/SuisseIntl-Regular.ttf) format("opentype");
}
@font-face {
    font-family: "Effra_Rg";
    src: local("Effra_Rg"), url(../font/Effra_Rg.ttf) format("truetype");
}
@font-face {
    font-family: "Effra_Bold";
    src: local("Effra_Bold"), url(../font/Effra-Bold.ttf) format("truetype");
}

/*                                             COLORS CHEATSHEET                                                */
/* 
                                          LIGHT                     DARK
--color-primary-lightest:           rgb(255, 255, 255);     rgb(255, 255, 255);
--color-primary-light:              rgb(235,245, 255);      rgb(235,245, 255);
--color-primary-regular:            rgb(30, 144, 255);      rgb(30, 144, 255);
--color-primary-dark:               rgb(0, 116, 230);       rgb(0, 116, 230);
--color-primary-darkest:            rgb(0, 90, 179);        rgb(0, 90, 179);
--color-secondary-lightest:         rgb(255, 255, 255);     rgb(255, 255, 255);
--color-secondary-light:            rgb(245, 245, 245);     rgb(245, 245, 245);
--color-secondary-regular:          rgb(115, 115, 115);     rgb(115, 115, 115);
--color-secondary-dark:             rgb(64, 64, 64);        rgb(64, 64, 64);
--color-secondary-darkest:          rgb(13, 13, 13);        rgb(13, 13, 13);
--color-accent-lightest:            rgb(255, 255, 255);     rgb(255, 255, 255);
--color-accent-light:               rgb(130, 224, 254);     rgb(130, 224, 254);
--color-accent-regular:             rgb(1, 193, 254);       rgb(1, 193, 254);
--color-accent-dark:                rgb(1, 160, 210);       rgb(1, 160, 210);
--color-accent-darkest:             rgb(1, 108, 142);       rgb(1, 108, 142);
--color-neutral-high-lightest:      rgb(255, 255, 255);     rgb(34,40,49);
--color-neutral-high-light:         rgb(252, 252, 253);     rgb(49,54,63);
--color-neutral-high-regular:       rgb(245, 247, 248);     rgb(60,67,77);
--color-neutral-high-dark:          rgb(209, 217, 224);     rgb(78, 87, 100);
--color-neutral-high-darkest:       rgb(179, 191, 204);     rgb(101, 112, 129);
--color-neutral-low-lightest:       rgb(217, 217, 217);     rgb(34,40,49);
--color-neutral-low-light:          rgb(166, 166, 166);     rgb(209,217,224);
--color-neutral-low-regular:        rgb(115, 115, 115);     rgb(249,250,251);
--color-neutral-low-dark:           rgb(64, 64, 64);        rgb(252,252,253);
--color-neutral-low-darkest:        rgb(13, 13, 13);        rgb(255,255,255);
--color-status-success-lightest:    rgb(154, 217, 179);     rgb(154, 217, 179);
--color-status-success-light:       rgb(120, 204, 154);     rgb(120, 204, 154);
--color-status-success-regular:     rgb(86, 191, 129);      rgb(86, 191, 129);
--color-status-success-dark:        rgb(69, 153, 103);      rgb(69, 153, 103);
--color-status-success-darkest:     rgb(52, 115, 77);       rgb(52, 115, 77);
--color-status-danger-lightest:     rgb(241, 148, 138);     rgb(241, 148, 138);
--color-status-danger-light:        rgb(236, 112, 99);      rgb(236, 112, 99);
--color-status-danger-regular:      rgb(231, 76, 60);       rgb(231, 76, 60);
--color-status-danger-dark:         rgb(204, 42, 25);       rgb(204, 42, 25);
--color-status-danger-darkest:      rgb(159, 33, 20);       rgb(159, 33, 20);
--color-status-warning-lightest:    rgb(255, 234, 128);     rgb(255, 234, 128);
--color-status-warning-light:       rgb(255, 225, 77);      rgb(255, 225, 77);
--color-status-warning-regular:     rgb(255, 212, 0);       rgb(255, 212, 0);
--color-status-warning-dark:        rgb(230, 191, 0);       rgb(230, 191, 0);
--color-status-warning-darkest:     rgb(179, 148, 0);       rgb(179, 148, 0);
--color-backdrop-lightest:          rgba(179, 191, 204, 0.5);rgba(179, 191, 204, 0.5);
--color-backdrop-light:             rgba(179, 191, 204, 0.6);rgba(179, 191, 204, 0.6);
--color-backdrop-regular:           rgba(179, 191, 204, 0.7);rgba(179, 191, 204, 0.7);
--color-backdrop-dark:              rgba(179, 191, 204, 0.8);rgba(179, 191, 204, 0.8);
--color-backdrop-darkest:           rgba(179, 191, 204, 0.9);rgba(179, 191, 204, 0.9);
--color-gradient-lightest:          rgb(179, 191, 204);     rgb(179, 191, 204);
--color-gradient-light:             rgb(179, 191, 204);     rgb(179, 191, 204);
--color-gradient-regular:           rgb(179, 191, 204);     rgb(179, 191, 204);
--color-gradient-dark:              rgb(179, 191, 204);     rgb(179, 191, 204);
--color-gradient-darkest:           rgb(179, 191, 204);     rgb(179, 191, 204); */
  

@layer base {
    html[data-theme="light"] {
        --color-primary-lightest: 255, 255, 255;
        --color-primary-light: 235,245, 255;
        --color-primary-regular: 30, 144, 255;
        --color-primary-dark: 0, 116, 230;
        --color-primary-darkest: 0, 90, 179;
        --color-secondary-lightest: 255, 255, 255;
        --color-secondary-light: 245, 245, 245;
        --color-secondary-regular: 115, 115, 115;
        --color-secondary-dark: 64, 64, 64;
        --color-secondary-darkest: 13, 13, 13;
        --color-accent-lightest: 255, 255, 255;
        --color-accent-light: 130, 224, 254;
        --color-accent-regular: 1, 193, 254;
        --color-accent-dark: 1, 160, 210;
        --color-accent-darkest: 1, 108, 142;
        --color-neutral-high-lightest: 255, 255, 255;
        --color-neutral-high-light: 252, 252, 253;
        --color-neutral-high-regular: 245, 247, 248;
        --color-neutral-high-dark: 209, 217, 224;
        --color-neutral-high-darkest: 179, 191, 204;
        --color-neutral-low-lightest: 217, 217, 217;
        --color-neutral-low-light: 166, 166, 166;
        --color-neutral-low-regular: 115, 115, 115;
        --color-neutral-low-dark: 64, 64, 64;
        --color-neutral-low-darkest: 13, 13, 13;
        --color-status-success-lightest: 154, 217, 179;
        --color-status-success-light: 120, 204, 154;
        --color-status-success-regular: 86, 191, 129;
        --color-status-success-dark: 69, 153, 103;
        --color-status-success-darkest: 52, 115, 77;
        --color-status-danger-lightest: 241, 148, 138;
        --color-status-danger-light: 236, 112, 99;
        --color-status-danger-regular: 231, 76, 60;
        --color-status-danger-dark: 204, 42, 25;
        --color-status-danger-darkest: 159, 33, 20;
        --color-status-warning-lightest: 255, 234, 128;
        --color-status-warning-light: 255, 225, 77;
        --color-status-warning-regular: 255, 212, 0;
        --color-status-warning-dark: 230, 191, 0;
        --color-status-warning-darkest: 179, 148, 0;
        --color-backdrop-lightest: rgba(179, 191, 204, 0.5);
        --color-backdrop-light: rgba(179, 191, 204, 0.6);
        --color-backdrop-regular: rgba(179, 191, 204, 0.7);
        --color-backdrop-dark: rgba(179, 191, 204, 0.8);
        --color-backdrop-darkest: rgba(179, 191, 204, 0.9);
        --color-gradient-lightest: 179, 191, 204;
        --color-gradient-light: 179, 191, 204;
        --color-gradient-regular: 179, 191, 204;
        --color-gradient-dark: 179, 191, 204;
        --color-gradient-darkest: 179, 191, 204;
    }
  
    html[data-theme="dark"] {
        --color-primary-lightest: 255, 255, 255;
        --color-primary-light: 235,245, 255;
        --color-primary-regular: 30, 144, 255;
        --color-primary-dark: 0, 116, 230;
        --color-primary-darkest: 0, 90, 179;
        --color-secondary-lightest: 255, 255, 255;
        --color-secondary-light: 245, 245, 245;
        --color-secondary-regular: 115, 115, 115;
        --color-secondary-dark: 64, 64, 64;
        --color-secondary-darkest: 13, 13, 13;
        --color-accent-lightest: 255, 255, 255;
        --color-accent-light: 130, 224, 254;
        --color-accent-regular: 1, 193, 254;
        --color-accent-dark: 1, 160, 210;
        --color-accent-darkest: 1, 108, 142;
        --color-neutral-high-lightest: 34,40,49;
        --color-neutral-high-light: 49,54,63;
        --color-neutral-high-regular: 60,67,77;
        --color-neutral-high-dark: 78, 87, 100;
        --color-neutral-high-darkest: 101, 112, 129;
        --color-neutral-low-lightest: 34,40,49;
        --color-neutral-low-light: 209,217,224;
        --color-neutral-low-regular: 249,250,251;
        --color-neutral-low-dark: 252,252,253;
        --color-neutral-low-darkest: 255,255,255;
        --color-status-success-lightest: 154, 217, 179;
        --color-status-success-light: 120, 204, 154;
        --color-status-success-regular: 86, 191, 129;
        --color-status-success-dark: 69, 153, 103;
        --color-status-success-darkest: 52, 115, 77;
        --color-status-danger-lightest: 241, 148, 138;
        --color-status-danger-light: 236, 112, 99;
        --color-status-danger-regular: 231, 76, 60;
        --color-status-danger-dark: 204, 42, 25;
        --color-status-danger-darkest: 159, 33, 20;
        --color-status-warning-lightest: 255, 234, 128;
        --color-status-warning-light: 255, 225, 77;
        --color-status-warning-regular: 255, 212, 0;
        --color-status-warning-dark: 230, 191, 0;
        --color-status-warning-darkest: 179, 148, 0;
        --color-backdrop-lightest: rgba(179, 191, 204, 0.5);
        --color-backdrop-light: rgba(179, 191, 204, 0.6);
        --color-backdrop-regular: rgba(179, 191, 204, 0.7);
        --color-backdrop-dark: rgba(179, 191, 204, 0.8);
        --color-backdrop-darkest: rgba(179, 191, 204, 0.9);
        --color-gradient-lightest: 179, 191, 204;
        --color-gradient-light: 179, 191, 204;
        --color-gradient-regular: 179, 191, 204;
        --color-gradient-dark: 179, 191, 204;
        --color-gradient-darkest: 179, 191, 204;
    }

    html[lang="ar"] {
        --font-primary: SuisseIntl-Bold;
        --font-secondary: SuisseIntl-Regular;
        --font-alt-primary: Effra_Bold;
        --font-alt-secondary: Effra_Rg;
    }

    html[lang="en"] {
        --font-primary: Effra_Bold;
        --font-secondary: Effra_Rg;
        --font-alt-primary: SuisseIntl-Bold;
        --font-alt-secondary: SuisseIntl-Regular;
        line-height: 1.1;
    }
}

html, body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    box-sizing: border-box;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: clip;
}
/* scrollbar */
::-webkit-scrollbar {
    display: none;
  }

html.lenis {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}

body {
    background: rgb(var(--color-primary-regular));
}